<template>
  <b-overlay
    id="overlay-background"
    :show="show"
    variant="primary"
    opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <b-card title="Kampanyalar" no-body>
      <b-card-body class="pb-1" v-if="campaigns.length > 0">
        <b-carousel
          id="carousel-caption"
          :interval="3000"
          style="text-shadow: 1px 1px 2px #333;"
          controls
          no-touch="false"
          indicators
        >
          <div v-for="(data, index) in campaigns" :key="index">
            <b-carousel-slide
              :caption-html="data.caption"
              class="card-img-overlay"
              :text="data.description"
              :img-src="data.coverImgUrl"
              style="height:100% !important;"
            >
            </b-carousel-slide>
          </div>
        </b-carousel>
      </b-card-body>
      <b-card-body class="pb-1" v-else>
        <div class="misc-wrapper">
          <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
              <h2 class="mb-1">Aktif kampanya bulunamadı. 👁️‍🗨️</h2>

              <b-img
                fluid
                :src="imgUrl"
                style="height: 250px"
                alt="Bizi takipte kalın."
              />
              <br />
              <br />
              <div class="d-flex justify-content-center"></div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BImg,
  BCard,
  BOverlay,
  BCardBody,
  BCardText,
  BCardTitle,
  BCarousel,
  BCarouselSlide,
  BCardHeader
} from "bootstrap-vue";

export default {
  components: {
    BImg,
    BCard,
    BOverlay,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCarousel,
    BCarouselSlide
  },
  data() {
    return {
      campaigns: [],
      downImg: require("@/assets/images/pages/undraw_Email_campaign_re_m6k5.svg")
    };
  },
  computed: {
    imgUrl() {
      return this.downImg;
    }
  },
  created() {
    this.show = true;

    this.$http
      .get("/Product/GetCampaigns")
      .then(response => {
        this.campaigns = response.data.result;
        this.show = false;
      })
      .catch(error => {
        this.show = false;

        this.$swal({
          title: "Hata!",
          text: error.response.data.responseException.exceptionMessage,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary"
          },
          confirmButtonText: "Kapat",
          buttonsStyling: false
        });
      });
  }
};
</script>
